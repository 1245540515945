<template>
  <div>
    <a-breadcrumb class="bread-crumb">
      <a-breadcrumb-item>
        <router-link :to="{ name: 'agent_monthly_bill_info', params: { agent_monthly_bill_id: billId }}">
          {{ productUsageOrder.month }}
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item> {{ productUsageOrder.agents_product_name }}</a-breadcrumb-item>
    </a-breadcrumb>

    <div v-if="['custom_month', 'custom_day'].includes(productUsageOrder.user_business_type_slug)">
      <show-agent-monthly-bill-custom-order :product-usage-order="productUsageOrder" />
    </div>
    <div v-else-if="productUsageOrder.user_business_type_slug === 'traffic_share'">
      <show-agent-monthly-bill-traffic-share-order :product-usage-order="productUsageOrder" />
    </div>
  </div>
</template>

<script>
import { findAgentProductUsageOrder } from '@/api/agent_product_usage_order'

export default {
  name: 'ShowAgentMonthlyBillCardUse',
  components: {
    ShowAgentMonthlyBillCustomOrder: () => import('@/views/agent_monthly_bills/card_use/show/Custom'),
    ShowAgentMonthlyBillTrafficShareOrder: () => import('@/views/agent_monthly_bills/card_use/show/TrafficShare')
  },
  data() {
    return {
      productUsageOrder: {}
    }
  },
  computed: {
    productUsageOrderId() {
      return parseInt(this.$route.params.card_use_id)
    },

    billId() {
      return parseInt(this.$route.query.bill_id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findAgentProductUsageOrder(this.productUsageOrderId).then((res) => {
        if (res.code === 0) {
          this.productUsageOrder = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bread-crumb {
  margin-bottom: 20px;
}
</style>
